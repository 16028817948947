import React from 'react';
import { useLocation } from 'react-router-dom';
const Footer = (props) => {

    const location = useLocation();

    let checkHome = 'home py-3 my-4';

    if (location.pathname != '/') {
        checkHome = 'viewPage py-3 my-4';
    }
    return (
        <>
            <footer className={checkHome}>
                <div className='container'>
                    <div className='logo-footer'><img width="40px" src="images/logofi.png" className='logo'/></div>
                    <ul className="nav justify-content-center border-bottom pb-3 mb-3">
                        {/* <li className="nav-item"><a href="#" className="nav-link px-2 "></a></li>
                        <li className="nav-item"><a href="#" className="nav-link px-2 "></a></li>
                        <li className="nav-item"><a href="#" className="nav-link px-2 "></a></li> */}                      
                    </ul>
                    <p className="text-center ">© 2024 KingKong Realm</p>
                </div>
            </footer>
        </>
    );
}

export default Footer;