import React from 'react';
import About from './About';
import Presale from './Presale';
import Roadmap from './Roadmap';
import Banner from './Banner';
import Tokenomics from './Tokenomics';
import Partners from './Partners';
import Team from './Team';
import Footer from '../Layout/Footer';
import SaleRound from './SaleRound';
import Game from './Game';
import Sale from './Sale';
import './home.css';
function Home(props) {
  return (
    <>
   
      <div className='page-home'>
        <Banner />
        <Sale />
        <Presale />
        <div className='wapper-bg'>
          <About />
          <Roadmap />
        </div>
        <Tokenomics />
        <SaleRound />
        <Team />
        <Partners />
        <Game />
      </div>

    </>
  );
}

export default Home;