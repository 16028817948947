import React from 'react';

function SaleRound(props) {
    return (
        <>
        <div id="sc_saleRound">
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <h2 className='text-center'>NFT Market</h2>
                        <div className='text-center nft-desc'>
                            <p>The NFT Exchange feature brings a unique experience to the KINGKONG Realm community, allowing KINGKONG to share and trade their NFTs using KKR tokens.</p>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='item-saleround'>
                            <div className='item-saleround-img'>
                                <img src="images/rs1.png" />
                            </div>
                            <div className='item-saleround-main'>
                                <h3>NFT Exchange</h3>
                                <p>Players can list their NFT on the exchange platform for trading.</p>
                                <p>Transactions are conducted using KKR as an intermediary currency.</p>
                            </div>
                        </div>                                                 
                    </div>
                     {/* end item-tokenomics */}      
                     
                     <div className='col-md-4'>
                        <div className='item-saleround'>
                            <div className='item-saleround-img'>
                                <img src="images/rs2.png" />
                            </div>
                            <div className='item-saleround-main'>
                                <h3>Transaction Conditions</h3>
                                <p>Set specific conditions and prices for exchanging NFT, such as requiring a certain amount of KKR</p>                                
                            </div>
                        </div>                                                 
                    </div>
                     {/* end item-tokenomics */}      
                     <div className='col-md-4'>
                        <div className='item-saleround'>
                            <div className='item-saleround-img'>
                                <img src="images/rs3.png" />
                            </div>
                            <div className='item-saleround-main'>
                                <h3>Transaction History</h3>
                                <p>Record transaction history for players to track and review information about past NFT exchanges.</p>                               
                            </div>
                        </div>                                                 
                    </div>
                     {/* end item-tokenomics */}      
                </div>
            </div>
        </div>
    </>
    );
}

export default SaleRound;