import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ endTime }) => {
    const calculateTimeLeft = () => {
        const difference = new Date(endTime) - new Date();
        let timeLeft = {};

        if (difference > 0) {
            const days = Math.floor(difference / (1000 * 60 * 60 * 24));
            const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((difference % (1000 * 60)) / 1000);

            timeLeft = {
                days,
                hours,
                minutes,
                seconds
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
    });

    return (
        <div>
            {timeLeft.days > 0 && (
                <>
                    <div className='countdowntime'>
                        <div className='box-countdowntime'>
                            <span className='item-time'>
                                <span className='time-t'>
                                    {timeLeft.days}
                                </span>
                                <span className='time-text'>
                                    Day
                                </span>
                            </span>
                            <span className='item-time-line'>:</span>
                            <span className='item-time'>
                                <span className='time-t'>
                                    {timeLeft.hours}
                                </span>
                                <span className='time-text'>
                                    Hour
                                </span>
                            </span>
                            <span className='item-time-line'>:</span>
                            <span className='item-time'>
                                <span className='time-t'>
                                    {timeLeft.minutes}
                                </span>
                                <span className='time-text'>
                                    Minute
                                </span>
                            </span>
                            <span className='item-time-line'>:</span>
                            <span className='item-time'>
                                <span className='time-t'>
                                    {timeLeft.seconds}
                                </span>
                                <span className='time-text'>
                                    Second
                                </span>
                            </span>
                        </div>
                    </div>
                </>

            )}
            {timeLeft.days === 0 && (
                <>
                    <div className='countdowntime'>
                        <div className='box-countdowntime'>
                            <span className='item-time'>
                                <span className='time-t'>
                                    {timeLeft.hours}
                                </span>
                                <span className='time-text'>
                                    Hour
                                </span>
                            </span>
                            <span className='item-time-line'>:</span>
                            <span className='item-time'>
                                <span className='time-t'>
                                    {timeLeft.minutes}
                                </span>
                                <span className='time-text'>
                                    Minute
                                </span>
                            </span>
                            <span className='item-time-line'>:</span>
                            <span className='item-time'>
                                <span className='time-t'>
                                    {timeLeft.seconds}
                                </span>
                                <span className='time-text'>
                                    Second
                                </span>
                            </span>
                        </div>
                    </div>
                </>
            )}
            {timeLeft.days <= 0 && timeLeft.hours <= 0 && timeLeft.minutes <= 0 && timeLeft.seconds <= 0 && (
                <></>
            )}
        </div>
    );
};

export default CountdownTimer;