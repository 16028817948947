import React from 'react';


function Banner(props) {

   
    return (
        <>
            <div id="sc_banner"  >
                <div className=' height100 d-flex justify-content-center align-items-center'>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-7">
                                <div className="inner height100 d-flex align-items-center justify-content-center">
                                    <div className="inner">
                                        <h5>Welcome to</h5>
                                        <h1>KingKong Realm</h1>
                                        <p>A unique Game-Fi project on the BSC network! KingKong Realm is not just a game; it's a fantastic experience focused on community consensus and innovative integration from the world of Game and Finance (Game-Fi) on the blockchain.</p>
                                        <p>Announcement: The KingKong Realm (KKR) token will be listed on PancakeSwap, with the listing price set at $0.40.</p>
                                        <h3 className='txt-tb'>Phase 2</h3>
                                        <p>
                                        Marketing on major social platforms, Participation in trade fairs, Game launch.</p>
                                        <p><strong>Q1 2025</strong>: Listing on MEXC Exchange</p>
                                        <p><strong>Q2 2025</strong>: Listing on Binance Exchange, Game feature updates, Graphic design enhancements, Registration on Coingecko and CoinMarketCap, Banner ad campaigns, Meme contest, Rebalancing in-game rewards to prevent inflation.  </p>                                      
                                       
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="bn-img">
                                    <img src="images/i-bn.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               
            </div>
        </>
    );
}

export default Banner;