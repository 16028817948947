import React from 'react';


function Game(props) {

   
    return (
        <>
            <div id="sc_game"  >
                <div className=' height100 d-flex justify-content-center align-items-center'>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-7">
                                <div className="inner height100 d-flex align-items-center justify-content-center">
                                    <div className="inner">
                                        <h5>Game</h5>
                                        <h1>KingKong Realm</h1>
                                        <p>"King Kong Realm" is an exciting action game where players control a giant gorilla navigating through challenging obstacles. During gameplay, players must skillfully guide the gorilla through dangerous terrains and avoid traps that appear along the way. In addition to dodging obstacles, an important task for players is to collect stars and coins scattered throughout the levels. These stars and coins not only help players achieve higher scores but can also be used to upgrade the gorilla's abilities, making it stronger to overcome more difficult levels later on. Each level is uniquely designed, providing a fresh and stimulating experience, creating an intense and engaging gaming adventure.</p>
                                        <p>In the next version of the game, we will upgrade to integrate account features. Players will earn KKR coins based on their scores, which can be used to purchase in-game accessories. Additionally, if you rank among the top 5 highest scorers of the week, you will have the opportunity to receive KKR coins according to your rank.</p>
                                        <div className="notlogin">                                           
                                            <a className='btn-play-game' href="http://game.kingkongrealm.com/" target='_blank'>Play games now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className='box-img-game'>
                                    <div className='bl-img-game'>
                                    <div className='blc-img-game'>
                                        <img src="images/game.png" />
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               
            </div>
        </>
    );
}

export default Game;