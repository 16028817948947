import React from 'react';

function Tokenomics(props) {
    return (
        <>
            <div id="sc_tokenomics">
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <h2 className='text-center'>Tokenomics</h2>
                            <div className='tokenomics-desc'>
                                <p>The total token supply of the project is 1,000,000,000 KKR.</p>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='item-tokenomics item-tokenomics1'>
                                <div className='item-tokenomics-img'>
                                    <span>44%</span>    
                                </div>
                                <div className='item-tokenomics-main'>
                                    <h3>Expo</h3>
                                    <h4>440.000.000 KKR</h4>
                                </div>
                            </div>                                                 
                        </div>
                         {/* end item-tokenomics */}      
                         <div className='col-md-4'>
                            <div className='item-tokenomics item-tokenomics2'>
                                <div className='item-tokenomics-img'>
                                <span>8%</span>    
                                </div>
                                <div className='item-tokenomics-main'>
                                    <h3>Presale</h3>
                                    <h4>80.000.000 KKR</h4>
                                </div>
                            </div>                                                 
                        </div>
                         {/* end item-tokenomics */}     
                         <div className='col-md-4'>
                            <div className='item-tokenomics item-tokenomics3'>
                                <div className='item-tokenomics-img'>
                                        <span>22%</span>    
                                </div>
                                <div className='item-tokenomics-main'>
                                    <h3>Liquidity</h3>
                                    <h4>220.000.000 KKR</h4>
                                </div>
                            </div>                                                 
                        </div>
                         {/* end item-tokenomics */}     
                         <div className='col-md-4'>
                            <div className='item-tokenomics item-tokenomics4'>
                                <div className='item-tokenomics-img'>
                                <span>10%</span>    
                                </div>
                                <div className='item-tokenomics-main'>
                                    <h3>Team</h3>
                                    <h4>100.000.000 KKR</h4>
                                </div>
                            </div>                                                 
                        </div>
                         {/* end item-tokenomics */}     
                         <div className='col-md-4'>
                            <div className='item-tokenomics item-tokenomics5'>
                                <div className='item-tokenomics-img'>
                                <span>8%</span>    
                                </div>
                                <div className='item-tokenomics-main'>
                                    <h3>Airdrop</h3>
                                    <h4>80.000.000 KKR</h4>
                                </div>
                            </div>                                                 
                        </div>
                         {/* end item-tokenomics */}     
                         <div className='col-md-4'>
                            <div className='item-tokenomics item-tokenomics6'>
                                <div className='item-tokenomics-img'>
                                <span>8%</span>    
                                </div>
                                <div className='item-tokenomics-main'>
                                    <h3>Marketing</h3>
                                    <h4>80.000.000 KKR</h4>
                                </div>
                            </div>                                                 
                        </div>
                         {/* end item-tokenomics */}     
                           
                    </div>
                </div>
            </div>
        </>
    );
}

export default Tokenomics;