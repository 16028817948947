import React from 'react';

import {
    BrowserRouter as Router,
    Route,
    Routes,
    NavLink
} from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
function Head(props) {
  

    let checkHome = 'home text-bg-white';

    return (
        <>

            <header className={checkHome}>
                <Navbar  expand="lg">
                    <Container>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Brand href="/"><img width="40px" src="images/logofi.png" className='logo' /></Navbar.Brand>
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="ml-auto">
                                <Nav.Link href="/">Home</Nav.Link>
                                <Nav.Link href="/#sc_about">About</Nav.Link>
                                <Nav.Link href="/#sc_presale">Presale</Nav.Link>
                                <Nav.Link href="/#sc_roadmap">Roadmap</Nav.Link>
                                <Nav.Link href="/#sc_tokenomics">Tokenomics</Nav.Link>
                                <Nav.Link href="/#sc_team">Our team</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                       
                    </Container>
                </Navbar>
            </header>

        </>
    );
}


export default Head;