import React from 'react';

function Roadmap(props) {
    return (
        <>
            <div id="sc_roadmap">
                <div id="bg_roadmap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h2 className='text-center title-h'><span>Roadmap</span></h2>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-10">
                                <div className="main-timeline">
                                    <div className="timeline">
                                        <div href="#" className="timeline-content">
                                            <div className="timeline-icon">
                                                <img src="images/r1.png" />
                                            </div>
                                            <h3 className="title">PHASE 1</h3>
                                            <p className="description">
                                                <ul>
                                                    <li>Building social media channels</li>
                                                    <li>Developing game content</li>
                                                    <li>Establishing game testing networks</li>
                                                    <li>Airdrop campaign</li>
                                                    <li>Game testing competition</li>
                                                    <li>Pre-sale</li>
                                                </ul>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="timeline">
                                        <div href="#" className="timeline-content">
                                            <div className="timeline-icon">
                                            <img src="images/r2.png" />
                                            </div>
                                            <h3 className="title">PHASE 2</h3>
                                            <p className="description">
                                                <ul>
                                                    <li>Marketing on major social platforms</li>
                                                    <li>Participation in trade shows</li>
                                                    <li>Game launch</li>
                                                    <li>Listing on top-tier CEX</li>
                                                    <li>Game feature updates</li>
                                                    <li>Game graphic design updates</li>
                                                    <li>Coingecko and CoinMarketCap registration</li>
                                                    <li>Running banner advertisements</li>
                                                    <li>Meme contest</li>
                                                    <li>Rebalancing game rewards to prevent inflation</li>
                                                </ul>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="timeline">
                                        <div href="#" className="timeline-content">
                                            <div className="timeline-icon"> <img src="images/r3.png" /></div>
                                            <h3 className="title">PHASE 3</h3>
                                            <p className="description">
                                                <ul>
                                                    <li>Developing the game on mobile applications</li>
                                                    <li>Launching the App on Google Play and Appstore</li>
                                                    <li>Influencer-led major marketing campaigns</li>
                                                    <li>Launching NFT market on all NFT platforms</li>
                                                    <li>Establishing Game Guilds and expanding the game community in all countries</li>
                                                </ul>
                                            </p>
                                        </div>
                                    </div>                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Roadmap;