import React from 'react';

function Team(props) {
    return (
        <>
            <div id="sc_team">
                <div id="sc_team_op">
                    <div className="container text-center">
                        <div className="row justify-content-center">
                            <div className="col-md-12">
                                <h2 className='title-team'><span>Our team</span></h2>
                            </div>
                            <div className="col-md-4">
                                <div className="item-team">
                                    <img src="images/te1.jpg" className='avatar-team' />
                                    <h4>Ajay B Panicker</h4>
                                    <span>CEO</span>
                                    <div>
                                    Skilled in Turn-Key Business Planning & Setup of e-Commerce firms; Setup, Customer Acquisition, Customer Support, and Electronic Payments.
                                    </div>                                   
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="item-team">
                                    <img src="images/te2.jpg" className='avatar-team' />
                                    <h4>Yusuf Bektas</h4>
                                    <span>Game Developer</span>
                                    <div>
                                    I am a Game Developer / Web3 Developer at On Off Games, a company that specializes in creating engaging and innovative games for various platforms and audiences.
                                    </div>
                                  
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="item-team">
                                    <img src="images/te3.jpg" className='avatar-team' />
                                    <h4>Suhaib T</h4>
                                    <span>Blockchain Developer</span>
                                    <div>
                                    Senior Software Developer. Full Stack Developer. Fintech. Blockchain Developer
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Team;
