import React from 'react';

function Sale(props) {
    return (
        <>
            <div id="sc_sale">
                <div id="bg_sale">
                    <div className="container">
                    <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <h2 className='text-center title-h'><span>Promotion with KKR</span></h2>
                                <p>The special offer program for KKR holders begins at 02:00 UTC on June 12, 2024. When purchasing KKR coins, you will receive the following additional rewards:</p>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-10">
                                <div className="list-sale">
                                    <div className="item-sale">
                                        <div className="item-sale-img">
                                            <img src="images/gold1.png" />
                                        </div>   
                                        <h4>25 KKR</h4>
                                        <div className="item-sale-btn">
                                        <a type='button' className='btn-buy-sale' href="#sc_presale">$ 100</a>
                                        </div>                                        
                                    </div>   
                                    {/* end item-sale                    */}
                                    <div className="item-sale">
                                        <div className="item-sale-img">
                                            <img src="images/gold2.png" />
                                        </div>   
                                        <h4>150 KKR</h4>
                                        <div className="item-sale-btn">
                                        <a type='button' className='btn-buy-sale' href="#sc_presale">$ 500</a>
                                        </div>     
                                    </div>   
                                    {/* end item-sale                    */}
                                    <div className="item-sale">
                                        <div className="item-sale-img">
                                            <img src="images/gold3.png" />
                                        </div>   
                                        <h4>400 KKR</h4>
                                        <div className="item-sale-btn">
                                        <a type='button' className='btn-buy-sale' href="#sc_presale">$ 1.000</a>
                                        </div>     
                                    </div>   
                                    {/* end item-sale                    */}
                                    <div className="item-sale">
                                        <div className="item-sale-img item-sale-img-m">
                                            <img src="images/gold4.png" />
                                        </div>   
                                        <h4>1.000 KKR</h4>
                                        <div className="item-sale-btn">
                                        <a type='button' className='btn-buy-sale' href="#sc_presale">$ 2.000</a>
                                        </div>     
                                    </div>   
                                    {/* end item-sale                    */}
                                    <div className="item-sale">
                                        <div className="item-sale-img item-sale-img-l">
                                            <img src="images/gold5.png" />
                                        </div>   
                                        <h4>3.000 KKR</h4>
                                        <div className="item-sale-btn">
                                        <a type='button' className='btn-buy-sale' href="#sc_presale">$ 5.000</a>
                                        </div>     
                                    </div>   
                                    {/* end item-sale                    */}
                                    <div className="item-sale">
                                        <div className="item-sale-img item-sale-img-l">
                                            <img src="images/gold6.png" />
                                        </div>   
                                        <h4>7.500 KKR</h4>
                                        <div className="item-sale-btn">
                                        <a type='button' className='btn-buy-sale' href="#sc_presale">$ 10.000</a>
                                        </div>     
                                    </div>   
                                    {/* end item-sale                    */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Sale;