import React from 'react';

function About(props) {
    return (
        <>
            <div id="sc_about">
                <div className="container text-center">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <h2 className='title-about'>About KingKong Realm</h2>
                            <div>
                                <p>
                                KingKong Realm is a browser-based game where players control King Kong in the kingdom, avoiding obstacles and collecting bananas along the way.  </p>
                                <p>
                                The game combines classic Arcade-style gameplay with Pixel Art graphics, bringing a classic aesthetic. The goal is to climb as high as possible while avoiding dangers like birds, helicopters, and falling objects. Collecting bananas earns points and helps KingKong progress further in the kingdom. 
                                </p>    
                                <p>      
                                With its simple yet addictive gameplay and nostalgic visuals, KingKong Realm offers an exciting and challenging experience for players of all ages.
                                </p>                               
                            </div>
                        </div>
                    </div>
                </div>
                <div className="icon-about">
                    <div className="box-icon-about">
                        <img src="images/rr.png" className='iabout2' />                       
                    </div>

                </div>
                <img src="images/bgeefd.png" className='iabout3' />
                <img src="images/ht.png" className='iabout4' />          
            </div>

        </>
    );
}

export default About;